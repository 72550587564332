.main {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  background-image: url('../src/images/Mask\ group.png');
  h1 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 118px;
    background: -webkit-linear-gradient(#ffc149, #fd6636);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0
  }
  h2 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 27px;
  }
  h3 {
    margin: 0
  }
  p {
    margin: 8px 0;
  }
}